import {Module} from '@nu-art/ts-common';
import {Default_SyncManagerNodePath, ModuleFE_SyncManager, Thunder} from '@nu-art/thunderstorm/frontend';
import {StorageKey_AdvisorEncounterId} from '@app/advisor/_entity/advisor-session/frontend';

class ModuleFE_AppModule_Class
	extends Module {


	protected init() {
		super.init();


		// set sync manager dynamic resolvers
		ModuleFE_SyncManager.setNodeContext(() => {
			const encounterId = StorageKey_AdvisorEncounterId.get();

			if (!encounterId)
				return Default_SyncManagerNodePath;

			return `/state/DataSyncManager/${encounterId}/syncData`;
		});


		ModuleFE_SyncManager.setSmartSyncUrl(`${Thunder.getInstance().getConfig().encounterManagerBackend}/data-sync-manager/smart-sync`);
	}

}

export const ModuleFE_AppModule = new ModuleFE_AppModule_Class();