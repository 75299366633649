//General
import {Const_LaunchSmart} from '@app/fhir';

export const Route_Home = 'home';
export const Route_Demo = 'demo';
export const Route_BroadcastTarget = 'boardcast-target';
export const Route_BroadcastHub = 'broadcast-hub';
export const RouteKey_AdvisorWrapper = 'advisor-wrapper';
export const RouteKey_NewAdvisorWrapper = 'new-advisor-wrapper';

//Advisor
export const Route_Advisor = 'advisor';
export const Route_LaunchSmartOnFhir = Const_LaunchSmart;
export const Route_LaunchDeployOnFhir = 'launch-deploy';
export const Route_LaunchAvatar = 'launch-avatar';